<template>
  <div class="assembly">
    <div class="videoBox">
      <div class="model" :class="load ? 'blackModel' : ''"></div>
      <div class="title">
        {{ $store.state.language.type == 1 ? data.title : data.title_en }}
      </div>
      <div class="title2">
        {{ $store.state.language.type == 1 ? data.describe : data.describe_en }}
      </div>
      <video :src="data.url" autoplay loop muted></video>
    </div>
    <div class="info">
      <div class="box1">
        <img src="./../../assets/img/mun.png" alt="" class="img1" />
      </div>
      <div class="type-name">BetterWorld全球青少年模拟联合国大会</div>
      <div class="type-name2">
        BetterWorld Global Youth Model United Nations Conference
      </div>
      <div class="type-name3 color">{{$store.state.cn_en_list[10]}}</div>
    </div>

    <div class="theme">
      <div class="title">
        <!-- <span>联合国秘书长<span class="color">安东尼奥·古特雷斯</span>先生致辞</span> -->
        <div v-html="$store.state.cn_en_list[11]"></div>
      </div>
      <div class="content">
        <!-- <img :src="theme.cover" alt=""> -->
        <video
          class="contentVideo"
          :src="theme.url"
          :poster="theme.cover"
          controls
          controlslist="nodownload"
          disablePictureInPicture
          @play="play"
          @ended="videoEnd"
        ></video>
      </div>
    </div>
	  <div class="theme theme2">
      <div class="title">
        <span class="color">{{$store.state.cn_en_list[12]}}</span>
        <div>{{$store.state.cn_en_list[13]}}</div>
      </div>
      <div class="content">
        <video
          class="contentVideo contentVideo1"
          :src="theme1.url"
          :poster="theme1.cover"
          controls
          controlslist="nodownload"
          disablePictureInPicture
          @play="play"
          @ended="videoEnd1"
        ></video>
      </div>
    </div>
    <div class="theme theme2">
      <div class="title">
        <span class="color">{{$store.state.cn_en_list[12]}}</span>
        <div>{{$store.state.cn_en_list[14]}}</div>
      </div>
      <div class="content">
        <video
          class="contentVideo contentVideo2"
          :src="theme2.url"
          :poster="theme2.cover"
          controls
          controlslist="nodownload"
          disablePictureInPicture
          @play="play"
          @ended="videoEnd2"
        ></video>
      </div>
    </div>
    <div class="Letterinfo">
      <div class="infoHead">
        <span class="text1">BetterWorldMUN</span>
        <span class="text2">{{$store.state.cn_en_list[15]}}</span>
        <span class="text3">{{$store.state.cn_en_list[16]}}</span>
        <img src="./../../assets/img/letter.png" alt="" />
      </div>
      <div class="textBoxContent">
        <div class="textContent">
          <div class="left">
            <div class="topletter">
              <img src="./../../assets/img/team.png" alt="" class="avatar" />
              <img
                src="./../../assets/img/letterLeft.png"
                alt=""
                class="letterLeft"
              />
            </div>
            <div class="bottomletter">
              <img src="./../../assets/img/letterDown.png" alt="" />
            </div>
          </div>
          <div class="right">
            <!-- <div
              class="desc"
              :class="item.top ? 'top' : ''"
              v-for="(item, index) in configData.text"
              :key="index"
            >
              <div :class="item.right ? 'rightText' : ''">{{ item.desc }}</div>
            </div> -->
            <div class="desc" >{{$store.state.cn_en_list[17]}}</div>
            <div class="desc" >{{$store.state.cn_en_list[18]}}</div>
            <div class="desc" >{{$store.state.cn_en_list[19]}}</div>
            <div class="desc" >{{$store.state.cn_en_list[20]}}</div>
            <div class="desc" >{{$store.state.cn_en_list[21]}}</div>
            <div class="desc" >{{$store.state.cn_en_list[22]}}</div>
          </div>
        </div>
        <div class="letterRight">
          <img src="./../../assets/img/letterRight.png" alt="" />
        </div>
      </div>

      <div class="name">
        <span class="name1">BetterWorldMUN</span>
        <span>{{$store.state.cn_en_list[15]}}</span>
        <span>{{$store.state.cn_en_list[23]}}</span>
        <span>{{$store.state.cn_en_list[24]}}</span>
      </div>
    </div>

    <div class="speaker">
      <div class="titleName">
        <span>{{$store.state.cn_en_list[25]}}</span>
      </div>
      <!-- <div class="list">
        <img :src="talkerImg" alt="" />
      </div> -->
      <div class="wrapper1">
                <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">

                    <el-carousel-item v-for="page in Math.ceil(zhuxituan.length / 3) " :key="page" >
                        <div class="partnerBox">
                            <div class="speakerBox radius" v-if="zhuxituan[3 * (page - 1)]">
                                <img class="radius" :src="zhuxituan[3 * (page - 1)].avatar" alt="">
                            </div>
                            <div class="speakerBox radius center" v-if="zhuxituan[3 * (page - 1) + 1]">
                                <img class="radius" :src="zhuxituan[3 * (page - 1) + 1].avatar" alt="">
                            </div>
                            <div class="speakerBox radius" v-if="zhuxituan[3 * (page - 1) + 2]">
                                <img class="radius" :src="zhuxituan[3 * (page - 1) + 2].avatar" alt="">
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="titleName">
        <span>{{$store.state.cn_en_list[26]}}</span>
      </div>
      <div class="wrapper">
        <el-carousel
          ref="carousel"
          class="carousel"
          arrow="never"
          :interval="10000"
          indicator-position="outside"
        >
          <el-carousel-item
            v-for="page in Math.ceil(talker.length / 3)"
            :key="page"
          >
            <div class="partnerBox">
              <div class="speakerBox" v-if="talker[3 * (page - 1)]">
                <img :src="talker[3 * (page - 1)].avatar" alt="" />
              </div>
              <div class="speakerBox center" v-if="talker[3 * (page - 1) + 1]">
                <img :src="talker[3 * (page - 1) + 1].avatar" alt="" />
              </div>
              <div class="speakerBox" v-if="talker[3 * (page - 1) + 2]">
                <img :src="talker[3 * (page - 1) + 2].avatar" alt="" />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="sort">{{$store.state.cn_en_list[27]}}</div>
      <!-- <div class="schedule">
        <div class="title">
          <span>重要日期</span>
        </div>
        <div class="content">
          <div class="left">
            <div class="timeBox">
              <div class="time">
                <div
                  class="timelist"
                  v-for="(item, index) in step[0]"
                  :key="index"
                >
                  <div class="lineCut" v-if="index != 0"></div>
                  <div class="listBox">
                    <div class="clock">
                      <img src="./../../assets//img//clock.png" alt="" />
                      <span>{{ item.time }}</span>
                    </div>
                    <div class="stepName">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="timeBox">
              <div class="time">
                <div
                  class="timelist"
                  v-for="(item, index) in step[1]"
                  :key="index"
                >
                  <div class="lineCut" v-if="index != 0"></div>
                  <div class="listBox">
                    <div class="clock">
                      <img src="./../../assets//img//clock.png" alt="" />
                      <span>{{ item.time }}</span>
                    </div>
                    <div class="stepName">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="place">
        <div class="title">
          <span>{{$store.state.cn_en_list[28]}}</span>
        </div>
        <el-carousel
          ref="carousel"
          class="carousel"
          arrow="never"
          :interval="10000"
          indicator-position="outside"
        >
          <el-carousel-item v-for="(item, index) in mapList" :key="index">
            <div class="content">
              <img :src="item" alt="" class="img" />
            </div>
          </el-carousel-item>
        </el-carousel>

        <div class="placeTip">
          <img src="./../../assets/img/placeTip.png" alt="" />
          <span>{{$store.state.cn_en_list[29]}}</span>
        </div>
      </div>

      <!-- 会议费用 -->
      <div class="money">
        <div class="title">{{$store.state.cn_en_list[57]}}</div>
        <div class="moneyBox">
          <div class="moneyContent">
            <div class="left">
              <div class="count">
                <div class="numberBox">
                  <div class="number">
                    <span class="moneyNum">¥</span>{{ CommitteeList.price }}
                  </div>
                  <div class="desc">
                    {{$store.state.cn_en_list[58]}}
                  </div>
                </div>
              </div>
              <div class="leftinfo">
                <div class="text">{{$store.state.cn_en_list[59]}}</div>
                <div class="text" v-html="$store.state.cn_en_list[60]">
                  
                </div>
                <div class="text" v-html="$store.state.cn_en_list[61]">
                  
                </div>
              </div>
            </div>
            <div class="right">
              <div class="rightDesc">
                <span>{{$store.state.cn_en_list[62]}}</span>
                <span>{{$store.state.cn_en_list[63]}}</span>
                <span>{{$store.state.cn_en_list[64]}}</span>
                <span>{{$store.state.cn_en_list[65]}}</span>
                <span>{{$store.state.cn_en_list[66]}}</span>
              </div>
              <div class="backTips">
                <div>
                  <span class="color">*</span>
                  <span >{{$store.state.cn_en_list[67]}}</span >
                </div>
                <div v-if="$store.state.language.type == 1">
                  <span class="color">*</span>
                  <span>手续费为总金额的0.6%。</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 会议住宿 -->
      <div class="live">
        <div class="title">{{$store.state.cn_en_list[43]}}</div>
        <div class="liveBox">
          <img src="./../../assets/img/live.png" alt="" class="img" />
          <div class="liveContent">
            <div class="liveTitle">{{$store.state.cn_en_list[44]}}</div>
            <div class="tips" :class="$store.state.language.type == 2 ? 'en_Tips' : '' ">
              <div class="tipBox">
                <div class="circle"></div>
                <span>{{$store.state.cn_en_list[45]}}</span>
              </div>
              <div class="tipBox">
                <div class="circle"></div>
                <span>{{$store.state.cn_en_list[46]}}</span>
              </div>
              <div class="tipBox">
                <div class="circle"></div>
                <span>{{$store.state.cn_en_list[48]}}</span>
              </div>

              <div class="tipBox">
                <div class="circle"></div>
                <span>{{$store.state.cn_en_list[47]}}</span>
              </div>
            </div>
            <div class="livedesc">
              <div class="descTitle">
                <span class="color">* </span>
                <span>{{$store.state.cn_en_list[49]}}</span>
              </div>
              <div class="label">
                <div class="livesort">（1）</div>
                <span
                  >{{$store.state.cn_en_list[50]}}</span
                >
              </div>
              <div class="label">
                <div class="livesort">（2）</div>
                <span
                  >{{$store.state.cn_en_list[51]}}</span
                >
              </div>
              <div class="label">
                <div class="livesort">（3）</div>
                <span
                  >{{$store.state.cn_en_list[52]}}</span
                >
              </div>
            </div>
            <div class="payNumBox">
              <div class="payNum">
                <span class="moneyNum">¥</span>{{ HotelList.price }}
              </div>
            </div>
            <div class="time" v-if="$store.state.language.type == 1">
              <span class="day">{{$store.state.cn_en_list[53]}}</span>
              <div class="countDate">
                <div class="line line1"></div>
                <span>{{$store.state.cn_en_list[54]}}</span>
                <div class="line line2"></div>
              </div>
              <span class="day">{{$store.state.cn_en_list[55]}}</span>
              <span class="typeName">{{$store.state.cn_en_list[56]}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 注册指引 -->
      <div class="register">
        <div class="title">{{$store.state.cn_en_list[30]}}</div>
        <div class="stepBox">
          <div class="stepItem">
            <div class="steptitle" v-html="$store.state.cn_en_list[31]"></div>
            <div class="stepinfo">{{$store.state.cn_en_list[32]}}</div>
          </div>
          <img src="./../../assets/img/next.png" alt="" class="next" />
          <div class="stepItem">
            <div class="steptitle" v-html="$store.state.cn_en_list[33]"></div>
            <div class="stepinfo">{{$store.state.cn_en_list[34]}}</div>
          </div>
          <img src="./../../assets/img/next.png" alt="" class="next" />
          <div class="stepItem">
            <div class="steptitle" v-html="$store.state.cn_en_list[35]"></div>
            <div class="stepinfo">{{$store.state.cn_en_list[36]}}</div>
          </div>
        </div>
      </div>

      <div class="contentBox">
        <div class="headTitle">{{$store.state.cn_en_list[37]}}</div>
        <div class="registerStep">
          <img
            src="./../../assets/img/registerStep1.png"
            alt=""
            class="registerStep1"
          />
          <div class="registerCode">
            <div class="stepBox">
              <div class="name">{{$store.state.cn_en_list[38]}}</div>
              <div class="stepWrapper">
                <div class="stepList">
                  <div class="lineListBox">
                    <img
                      src="./../../assets/img/clock.png"
                      alt=""
                      class="clockImg"
                    />
                    <span>{{$store.state.cn_en_list[39]}}</span>
                  </div>
                </div>
                <div class="stepList">
                  <div class="lineCut"></div>
                  <div class="lineListBox">
                    <img
                      src="./../../assets/img/clock.png"
                      alt=""
                      class="clockImg"
                    />
                    <span>{{$store.state.cn_en_list[40]}}</span>
                  </div>
                </div>
                <div class="stepList">
                  <div class="lineCut"></div>
                  <div class="lineListBox">
                    <img
                      src="./../../assets/img/clock.png"
                      alt=""
                      class="clockImg"
                    />
                    <span>{{$store.state.cn_en_list[41]}}</span>
                  </div>
                </div>
                <div class="stepList">
                  <div class="lineCut"></div>
                  <div class="lineListBox">
                    <img
                      src="./../../assets/img/clock.png"
                      alt=""
                      class="clockImg"
                    />
                    <span>{{$store.state.cn_en_list[42]}}</span>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="./../../assets/img/registerStep2.png"
              alt=""
              class="registerStep2"
            />
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import {
  banner,
  video,
  presidium,
  partner,
  committee,
  hotel,
  config
} from "./../../api/api";
export default {
  data() {
    return {
      data: {
        title: "",
        describe: "",
        url: "",
      },
      load: false,
      talkerImg: "",
      mapList: [],
      parterList: [],
      parter: [],
      talker: [],
      zhuxituan: [],
      importantList: [],
      theme: {},
      theme1: {},
      theme2: {},
      step: [
        [
          {
            time: "2024年3月3日",
            name: "常规报名开始",
          },
          {
            time: "2024年5月1日",
            name: "组委会、议题公布",
          },
          {
            time: "2024年7月1日",
            name: "常规报名截至",
          },
          {
            time: "2024年7月2日",
            name: "候补报名开始",
          },
        ],
        [
          {
            time: "2024年7月15日",
            name: "候补报名截止",
          },
          {
            time: "2024年7月30日",
            name: "会议开幕",
          },
          {
            time: "2024年8月2日",
            name: "会议闭幕",
          },
        ],
      ],
      CommitteeList: {},
      eb_price_time: {
        day: "00",
        hour: "00",
        min: "00",
        sec: "00",
        time: 0,
      }, //早鸟价倒计时
      HotelList: {},
    };
  },
  mounted() {
    config().then(res => {
      if(res.data.code == 10000){
        var obj = {}
        res.data.data.forEach(item => {
            obj[item.key] = item
        })
        obj.nowTime = res.data.time * 1000
        localStorage.setItem("config", JSON.stringify(obj));
        this.talkerImg = JSON.parse(localStorage.config).talker_pc.value[0];
        this.mapList = JSON.parse(localStorage.config).activity_address.value;
      }
    })
    
    if (this.$route.query.bottom) {
      var element = document.getElementById("app"); // 获取需要滚动的节点
      element.scrollTop = 10000000; // 滚动到距离顶部100像素的位置
    }
    this.getCommitteeList();
    this.getHotelList();
    banner({ seat: 4 }).then((res) => {
      if (res.data.code === "10000") {
        this.data = res.data.data[0];
        // console.log(this.data)
        if (
          /Safari/.test(navigator.userAgent) &&
          !/Chrome/.test(navigator.userAgent)
        ) {
          setTimeout(() => {
            this.load = true;
          }, 800);
        } else {
          this.load = true;
        }
        var eb_price_Defer =
          (JSON.parse(localStorage.config).eb_price_time.value -
            res.data.time * 1000) /
          1000;
        if (eb_price_Defer > 0) {
          this.eb_price_time.time = eb_price_Defer;
          setInterval(() => {
            this.ebPriceDeferFn();
          }, 1000);
        }
      } else {
        this.$message.error(res.data.msg);
      }
    });
    //精彩瞬间
    presidium().then((res) => {
      if (res.data.code === "10000") {
        this.talker = res.data.data;
      } else {
        this.$message.error(res.data.msg);
      }
    });
    //主席团成员
    presidium({type : 2}).then((res) => {
      if (res.data.code === "10000") {
        this.zhuxituan = res.data.data;
      } else {
        this.$message.error(res.data.msg);
      }
    });
    partner({ device: 1 }).then((res) => {
      if (res.data.code === "10000") {
        this.parter = res.data.data["1"];
        this.parterList = res.data.data["2"];
        this.importantList = res.data.data["3"];
      } else {
        this.$message.error(res.data.msg);
      }
    });
    //大会主题
    video({ type: 1 }).then((res) => {
      if (res.data.code === "10000") {
        this.theme = res.data.data[0];
      } else {
        this.$message.error(res.data.msg);
      }
    });
    video({ type: 4 }).then((res) => {
      if (res.data.code === "10000") {
        this.theme1 = res.data.data[0];
      } else {
        this.$message.error(res.data.msg);
      }
    });
    video({ type: 5 }).then((res) => {
      if (res.data.code === "10000") {
        this.theme2 = res.data.data[0];
      } else {
        this.$message.error(res.data.msg);
      }
    });
  },
  methods: {
    play(e){
      var className = e.currentTarget.className;
      var videoBox = document.querySelectorAll('.contentVideo')
      for (var i = 1; i < videoBox.length + 1; i++) {
        if (className === videoBox[i - 1].className) {
        //console.log('播放视频不做处理');
        } else {
        //console.log('暂停其他正在播放的视频');
        videoBox[i - 1].pause();
        }
      }
    },
    goAudience() {
      this.$router.push("/audience");
    },
    videoEnd() {
      let myVideo = document.querySelector(".contentVideo");
      myVideo.currentTime = 0.5;
    },
    videoEnd1() {
      let myVideo = document.querySelector(".contentVideo1");
      myVideo.currentTime = 0.5;
    },
    videoEnd2() {
      let myVideo = document.querySelector(".contentVideo2");
      myVideo.currentTime = 0.5;
    },
    //委员会主题查询
    getCommitteeList() {
      committee().then((res) => {
        if (res.data.code === "10000") {
          res.data.data.theme_list.forEach((item) => {
            item.theme_name = JSON.parse(item.theme_name);
          });
          res.data.data.expenseDetail = res.data.data.expense_detail.split(" ");
          this.CommitteeList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //早鸟价倒计时
    ebPriceDeferFn() {
      var time = this.eb_price_time.time;

      let daydiff = 24 * 60 * 60;
      let hoursdiff = 60 * 60;
      let minutesdiff = 60;
      let day = Math.floor(time / daydiff); //天数
      let hour = Math.floor((time - day * daydiff) / hoursdiff);
      let min = Math.floor(
        (time - day * daydiff - hour * hoursdiff) / minutesdiff
      );
      let sec = Math.floor(
        time - day * daydiff - hour * hoursdiff - min * minutesdiff
      );
      if (day < 10) {
        this.eb_price_time.day = "0" + day;
      } else {
        this.eb_price_time.day = day;
      }
      if (hour < 10) {
        this.eb_price_time.hour = "0" + hour;
      } else {
        this.eb_price_time.hour = hour;
      }
      if (min < 10) {
        this.eb_price_time.min = "0" + min;
      } else {
        this.eb_price_time.min = min;
      }
      if (sec < 10) {
        this.eb_price_time.sec = "0" + sec;
      } else {
        this.eb_price_time.sec = sec;
      }
      this.eb_price_time.time -= 1;
    },
    //酒店列表
    getHotelList() {
      hotel().then((res) => {
        if (res.data.code === "10000") {
          res.data.data.forEach((item) => {
            item.introduceList = item.introduce.split(" ");
          });
          this.HotelList = res.data.data[0];
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  watch: {
    
  },
};
</script>
<style lang="less" scoped>
.assembly {
  background: #1c1c1c;

  padding-bottom: 151px;
  .live {
    width: 1200px;
    margin: 87px auto 0;

    .liveBox {
      width: 100%;
      height: 419px;
      background: #333333;
      border-radius: 8px;
      margin: 53px auto 0;
      display: flex;
      position: relative;

      .liveContent {
        margin-top: 36px;
        margin-left: 36px;

        .liveTitle {
          font-size: 32px;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          line-height: 42px;
        }
        .tips {
          width: 492px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 7px;
          overflow: hidden;
        }
        .en_Tips{
          width: 800px;
        }
        .tipBox {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #aaaaaa;
          line-height: 20px;
          margin-top: 24px;
        }
        .tipBox .circle {
          width: 9px;
          height: 9px;
          background: #aaaaaa;
          margin-right: 5px;
          margin-top: 0;
          border-radius: 50%;
        }

        .livedesc {
          margin: 31px auto 0;
          font-size: 16px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #aaaaaa;
          line-height: 22px;
          text-align: left;
        }
        .descTitle {
          text-align: left;
          font-size: 16px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #aaaaaa;
          line-height: 22px;
        }
        .label {
          display: flex;
          text-align: left;
          font-size: 16px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #aaaaaa;
          line-height: 22px;
          margin-top: 10px;
        }
        .payNum {
          font-size: 32px;
          font-family: YouSheBiaoTiHei;
          color: #3da6d5;
          line-height: 42px;
        }
        .payNumBox {
          position: absolute;
          right: 24px;
          top: 36px;
          display: flex;
          align-items: center;
        }
        .oldNum {
          font-size: 16px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #d1d1d1;
          line-height: 22px;
          margin-left: 12px;
          text-decoration: line-through;
        }
      }

      .img {
        width: 261px;
        height: 353px;
        margin-left: 41px;
        margin-top: 36px;
      }
      .time {
        width: 413px;
        height: 36px;
        background: #1c1c1c;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 27px;
        .countDate {
          width: 33px;
          height: 18px;
          background: #1c1c1c;
          border-radius: 9px;
          border: 1px solid #979797;
          text-align: center;
          position: relative;
          margin: 0 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 12px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #ffffff;
            line-height: 16px;
          }
          .line {
            position: absolute;
            width: 3px;
            height: 1px;
            border: 1px solid #979797;
            top: 50%;
            transform: translateY(-50%);
          }
          .line1 {
            left: -4px;
          }
          .line2 {
            right: -4px;
          }
        }

        .day {
          font-size: 16px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #ffffff;
          line-height: 22px;
        }
        .typeName {
          font-size: 16px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #ffffff;
          line-height: 16px;
          padding-left: 8px;
          border-left: 1px solid #979797;
          margin-left: 8px;
        }
      }
      .timeContent {
        display: flex;
        align-items: center;
        margin-top: 31px;
      }
      .moneyDesc {
        font-size: 14px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        color: #d1d1d1;
        line-height: 20px;
        margin-left: 14px;
      }
    }

    .title {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 73px;
      text-align: center;
    }
  }

  .money {
    width: 1200px;
    margin: 122px auto 0;

    .title {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 73px;
      text-align: center;
    }

    .time {
      font-size: 15px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 20px;
      position: absolute;
      right: 51px;
      bottom: 258px;
      display: flex;
      align-items: center;

      .timeBox {
        display: flex;
        align-items: center;
        margin-left: 12px;

        .label {
          width: 28px;
          height: 28px;
          background: #333333;
          border-radius: 4px;
          color: #3da6d5;
          line-height: 28px;
          text-align: center;
          margin-right: 4px;
        }
      }

      .timeBox:nth-of-type(1) {
        margin: 0;
      }
    }

    .moneyContent {
      display: flex;
      position: absolute;
      flex-direction: column;
      right: 32px;
      top: 24px;
      width: 344px;

      .left {
        .count {
          .number {
            font-size: 32px;
            font-family: YouSheBiaoTiHei;
            color: #3da6d5;
            line-height: 45px;
          }
          .oldNum {
            font-size: 16px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #d1d1d1;
            line-height: 22px;
            margin-left: 12px;
            text-decoration: line-through;
          }
          .moneyDesc {
            font-size: 14px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #d1d1d1;
            line-height: 20px;
            margin-top: 8px;
          }
        }

        .desc {
          font-size: 14px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #666666;
          line-height: 22px;
          margin-top: 5px;
        }

        .leftinfo {
          font-size: 16px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #d1d1d1;
          line-height: 22px;
          margin-top: 28px;

          .color {
            color: #3da6d5;
          }

          .text:nth-of-type(3) {
            margin-top: 8px;
          }
        }
      }

      .right {
        margin-top: 56px;
        .rightDesc {
          span {
            display: block;
            font-size: 16px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #d1d1d1;
            line-height: 22px;
          }
        }
      }
      .backTips {
        font-size: 14px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        color: #d1d1d1;
        line-height: 20px;
        margin-top: 8px;
      }
    }

    .moneyBox {
      height: 516px;
      margin: 48px auto 0;
      background-image: url("./../../assets/img/money.png");
      background-size: 100% 100%;
      position: relative;

      .name {
        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 40px;
        position: absolute;
        left: 24px;
        top: 175px;

        span {
          display: block;
        }
      }
    }
  }

  .register {
    width: 1200px;
    margin: 120px auto 0;

    .stepBox {
      margin-top: 64px;
      display: flex;
      align-items: center;
      justify-content: center;

      .stepItem {
        width: 282px;
        height: 220px;
        background-image: url("./../../assets/img/step.png");
        background-size: 100% 100%;
        overflow: hidden;

        .stepinfo {
          font-size: 20px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          margin-left: 16px;
          width: 242px;
        }

        .steptitle {
          margin: 82px 0 16px 16px;
          font-size: 44px;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          line-height: 57px;

          .color {
            color: #3da6d5;
          }
        }
      }

      .next {
        width: 50px;
        height: 50px;
        margin-left: 64px;
        margin-right: 63px;
      }
    }

    .title {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 73px;
      text-align: center;
    }
  }

  .contentBox {
    width: 1200px;
    margin: 120px auto 0;
    overflow: hidden;

    .headTitle {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 73px;
      text-align: center;
    }

    .root {
      overflow: hidden;
      border-radius: 8px;
      margin-top: 48px;

      .rootBox {
        width: 600px;
        height: 421px;
        position: relative;
        float: left;
        cursor: pointer;
        overflow: hidden;

        .title {
          padding: 0 32px;
          height: 78px;
          background: rgba(56, 56, 56, 0.2);
          border: 1px solid #ffffff;
          font-size: 42px;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          line-height: 76px;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 8px;
          z-index: 1;
        }

        img {
          width: 100%;
          height: 100%;
        }

        .model {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .rootBox:hover {
        .info {
          color: #fff;
        }

        .model {
          background: rgba(0, 0, 0, 0.6);
        }

        img {
          transform: scale(1.1);
          transition: 0.2s;
        }

        .title {
          background: #3da6d5;
          border-color: #3da6d5;
        }
      }
    }

    img {
      display: block;
    }
  }

  .partner {
    width: 1200px;
    margin: 120px auto;
    overflow: hidden;

    .partnertitle {
      font-size: 56px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 73px;
      text-align: center;
    }

    .title {
      font-size: 32px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 42px;
      text-align: center;
    }

    .parterContent {
      margin-top: 64px;
      display: flex;
      justify-content: space-between;
    }

    .carousel {
      width: 384px;
      height: 430px;
      margin-top: 30px;

      /deep/ .el-carousel__container {
        height: 403px;
        background: #000;
        border-radius: 8px;

        .content {
          width: 100%;
          height: 100%;
          border-radius: 8px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
      }
    }

    /deep/ .el-carousel__indicator--horizontal {
      padding: 15px 4px 0;
    }
  }

  .schedule {
    width: 1200px;
    margin: 120px auto 0;
    overflow: hidden;

    .content {
      margin-top: 80px;
      display: flex;

      .left {
        display: flex;
        flex-direction: column;
        margin-left: 81px;
      }

      .right {
        margin-left: 162px;
        display: flex;
        flex-direction: column;
      }

      .timeBox {
        .time {
          margin-top: 37px;

          .timelist {
            .lineCut {
              width: 1px;
              height: 34px;
              opacity: 0.6;
              border: 1px solid #ffffff;
              margin: 9px 0 5px 11px;
            }

            .listBox {
              display: flex;
              align-items: center;

              .stepName {
                font-size: 20px;
                font-family: "AlibabaPuHuiTi_2_55_Regular";
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
              }
            }

            .clock {
              font-size: 24px;
              font-family: YouSheBiaoTiHei;
              color: #3da6d5;
              line-height: 31px;
              display: flex;
              align-items: center;
              width: 319px;

              img {
                width: 24px;
                height: 24px;
                margin-right: 40px;
              }
            }
          }
        }

        .day {
          font-size: 28px;
          font-family: YouSheBiaoTiHei;
          color: #3da6d5;
          line-height: 36px;
        }
      }

      .second {
        margin-top: 119px;
      }
    }

    .title {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 73px;
      text-align: center;
    }
  }

  .wrapper {
    height: 256px;
    margin-top: 48px;

    .carousel {
      height: 280px;

      /deep/ .el-carousel__container {
        height: 256px;
      }
    }

    /deep/ .el-carousel__indicator--horizontal {
      padding: 15px 4px 0;
    }

    .speakerBox {
      text-align: center;
      width: 384px;
      float: left;
      height: 256px;

      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
    }

    .center {
      margin: 0 24px 0;
    }
  }
  .wrapper1{
        height: 720px;
        margin-top: 32px;
        .carousel{
            height: 720px;
            /deep/ .el-carousel__container{
                height: 695px;
            }
        }
        /deep/ .el-carousel__indicator--horizontal{
            padding: 15px 4px 0;
        }
        .speakerBox{
            text-align: center;
            width: 384px;
            height: 695px;
            float: left;
            img{
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
            .title{
                font-size: 22px;
                font-family: YouSheBiaoTiHei;
                color: #000000;
                line-height: 29px;
                margin: 14px auto 0;
            }
            .info{
                width: 281px;
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #000000;
                line-height: 28px;
                margin: 9px auto 0;
                text-align: left;
            }
        }
        .center{
            margin: 0 24px 0;
        }
    }
  .speaker {
    width: 1200px;
    margin: 0 auto;
    .sort {
      font-size: 19px;
      font-family: AlibabaPuHuiTi_2_55_Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 29px;
      margin-top: 41px;
      text-align: center;
    }

    .list {
      width: 1200px;
      margin: 50px auto 0;

      img {
        width: 100%;
        height: 440px;
        border-radius: 8px;
      }

      .text {
        font-size: 19px;
        font-family: AlibabaPuHuiTi_2_55_Regular;
        color: #ffffff;
        line-height: 26px;
        margin-top: 10px;
        text-align: center;
      }
    }

    .titleName {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 73px;
      text-align: center;
      margin: 120px auto 0;
    }

    .place {
      width: 1200px;
      margin: 120px auto 0;
      overflow: hidden;

      .placeTip {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;

        img {
          width: 16px;
          height: 24px;
        }

        span {
          font-size: 20px;
          font-family: AlibabaPuHuiTi_2_55_Regular;
          color: #ffffff;
          line-height: 28px;
          margin-left: 24px;
        }
      }

      .carousel {
        height: 790px;
        margin: 48px auto 0;

        /deep/ .el-carousel__container {
          height: 756px;
        }
      }

      .content {
        width: 100%;
        height: 756px;

        .img {
          width: 100%;
          height: 100%;
          background: #9f9f9f;
          border-radius: 8px;
        }
      }

      .title {
        font-size: 34px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 73px;
        text-align: center;
      }
    }
  }

  .theme {
    width: 1200px;
    margin: 16px auto 0;

    .title {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }

    .color {
      color: #3da6d5;
    }

    .content {
      width: 1200px;
      height: 674px;
      border-radius: 8px;
      margin: 32px auto 0;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }

      video {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
  .theme2{
	margin-top: 60px;
  }
  .info {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;

    .box1 {
      display: flex;
      align-items: center;

      .img1 {
        width: 268px;
        height: 57px;
      }
    }

    .type-name {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 73px;
      margin-top: 30px;
    }

    .type-name2 {
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      color: #ffffff;
      line-height: 31px;
      margin-top: 8px;
    }
    .type-name3 {
      font-size: 34px;
      font-family: YouSheBiaoTiHei;
      color: #3da6d5;
      line-height: 40px;
      margin-top: 16px;
    }
  }

  .videoBox {
    // background-image: url('./../../assets/img/assemblyBanner.png');
    video {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
    }
  }
}
.Letterinfo {
  width: 1198px;
  margin: 120px auto 0;
  .infoHead {
    display: flex;
    align-items: center;
    flex-direction: column;
    .text1 {
      font-size: 32px;
      font-family: YouSheBiaoTiHei;
      color: #3da6d5;
      line-height: 38px;
    }
    .text2 {
      font-size: 32px;
      font-family: YouSheBiaoTiHei;
      color: #3da6d5;
      line-height: 38px;
      margin-top: 10px;
    }
    .text3 {
      font-size: 32px;
      font-family: YouSheBiaoTiHei;
      color: #3da6d5;
      line-height: 38px;
      margin-top: 10px;
    }
    img {
      width: 96px;
      height: 86px;
      margin-top: 18px;
    }
  }
  .textBoxContent {
    font-size: 20px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;
    margin-top: 32px;
    padding-top: 43px;
    padding-bottom: 96px;
    position: relative;
    .letterRight {
      height: 48px;
      width: 55px;
      position: absolute;
      right: 0;
      bottom: 96px;
      img {
        width: 55px;
        height: 48px;
      }
    }
    .textContent {
      display: flex;
    }
    .left {
      width: 175px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .bottomletter {
        img {
          width: 36px;
          height: 180px;
        }
      }
      .topletter {
        display: flex;
        .avatar {
          width: 150px;
          height: 150px;
          margin-top: 5px;
        }
        .letterLeft {
          width: 25px;
          height: 22px;
        }
      }
    }
    .right {
      flex: 1;
      margin-left: 15px;
      .desc {
        margin-top: 48px;
      }
      .desc:nth-of-type(1) {
        margin-top: 0;
      }
      .desc:nth-of-type(2) {
        margin-top: 37px;
      }
    }
  }
  .top {
    margin-top: 45px;
  }
  .rightText {
    text-align: right;
  }
  .name {
    font-size: 20px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    text-align: right;
    span {
      margin-top: 8px;
      display: block;
    }
    .name1 {
      font-size: 24px;
      margin-top: 24px;
    }
  }
}
.registerStep {
  width: 1200px;
  height: 419px;
  margin: 48px auto 0;
  display: flex;
  align-items: center;
  .registerCode {
    width: 850px;
    height: 419px;
    background: #333333;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  .stepBox {
    flex: 1;
    margin-left: 72px;
    height: 100%;
    .name {
      font-size: 44px;
      line-height: 42px;
      font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
      font-weight: 400;
      color: #ffffff;
      margin-top: 35px;
    }
    .stepWrapper {
      margin-top: 35px;
      .lineCut {
        width: 1px;
        height: 34px;
        background: #fff;
        margin: 11px 12px;
      }
      .lineListBox {
        font-size: 24px;
        font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
        font-weight: 400;
        color: #3da6d5;
        line-height: 28px;
        display: flex;
        align-items: center;
        .clockImg {
          width: 25px;
          height: 25px;
          margin-right: 40px;
        }
      }
    }
  }
  .registerStep1 {
    width: 350px;
    height: 100%;
  }
  .registerStep2 {
    width: 350px;
    height: 350px;
    margin-right: 40px;
  }
}
</style>